import React, { useState, useEffect, useRef } from 'react'

const VideoPlayer = ({ videoUrl }) => {
  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadProgress, setDownloadProgress] = useState(0)
  const [videoSrc, setVideoSrc] = useState('')
  const [showStartBtn, setShowStartBtn] = useState(false)
  const [isLongPress, setIsLongPress] = useState(false)

  const abortController = useRef(null)
  const longPressTimeout = useRef(null)

  useEffect(() => {
    const checkVideoCache = async () => {
      try {
        const cache = await caches.open('video-cache')
        const cachedResponse = await cache.match(videoUrl)

        if (cachedResponse) {
          const blob = await cachedResponse.blob()
          setVideoSrc(URL.createObjectURL(blob))
          console.log('Video loaded from cache')
        } else {
          setShowStartBtn(true)
        }
      } catch (error) {
        console.error('Error fetching video from CacheStorage:', error)
      }
    }

    checkVideoCache()

    return () => {
      if (abortController.current) {
        abortController.current.abort()
        abortController.current = null
      }
    }
  }, [videoUrl])

  const handleVideoError = (event) => {
    console.error('Error playing video:', event)
    alert('无法播放视频，请确保视频格式和编码兼容')
  }

  const startDownload = async () => {
    const saveVideoToCache = async (response) => {
      try {
        const cache = await caches.open('video-cache')
        await cache.put(videoUrl, response.clone())
        const blob = await response.blob()
        setVideoSrc(URL.createObjectURL(blob))
        console.log('Video cached successfully')
      } catch (error) {
        console.error('Error saving video to CacheStorage:', error)
      }
    }

    const downloadVideo = async () => {
      setIsDownloading(true)
      setDownloadProgress(0)

      try {
        abortController.current = new AbortController()
        const response = await fetch(videoUrl, {
          signal: abortController.current.signal,
        })
        const reader = response.body.getReader()
        const chunks = []
        let receivedLength = 0
        const contentLength = +response.headers.get('Content-Length') || 0

        while (true) {
          const { done, value } = await reader.read()
          if (done) break
          chunks.push(value)
          receivedLength += value.length
          setDownloadProgress(
            Math.floor((receivedLength / contentLength) * 100)
          )
        }

        const blob = new Blob(chunks, {
          type: response.headers.get('Content-Type') || 'video/mp4',
        })
        await saveVideoToCache(new Response(blob))

        console.log('Video downloaded and cached')
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Video download aborted')
        } else {
          console.error('Error downloading video:', error)
        }
      } finally {
        setIsDownloading(false)
      }
    }

    setShowStartBtn(false)
    downloadVideo()
  }

  const handleLongPressStart = () => {
    longPressTimeout.current = setTimeout(() => {
      setIsLongPress(true)
      setVideoSrc(videoUrl)
      setShowStartBtn(false)
      setIsDownloading(false)
      console.log('Playing video without cache')
    }, 2000) // 2 seconds
  }

  const handleLongPressEnd = () => {
    clearTimeout(longPressTimeout.current)
    setIsLongPress(false)
  }

  return (
    <div className="c-video-container">
      {showStartBtn && (
        <button className="downloadBtn" onClick={startDownload}>
          播放视频
        </button>
      )}
      {!showStartBtn && isDownloading && (
        <div className="loading-container">
          <div className="loading-circle"></div>
          <p className="loading-percent">{downloadProgress}%</p>
          <p className="loading-text">视频缓存中，请等待...</p>
        </div>
      )}
      {!showStartBtn && !isDownloading && videoSrc && (
        <video
          className="video-player"
          src={videoSrc}
          controls
          onError={handleVideoError}
          style={{ objectFit: 'fill' }}
          controlsList="nodownload"
          playsInline
          webkit-playsinline=""
          x5-playsinline=""
          autoPlay
          onTouchStart={handleLongPressStart}
          onTouchEnd={handleLongPressEnd}
          onMouseDown={handleLongPressStart}
          onMouseUp={handleLongPressEnd}
          onMouseLeave={handleLongPressEnd}
        />
      )}
    </div>
  )
}

export default VideoPlayer
