import React, { useEffect, useState } from 'react'
import { formatOssUrl, jqImage } from '@/api/url'
import './home.scss'
import { get } from '@/api/http'
import { Link } from 'react-router-dom'
import { getItem } from '@/utils/storage'

const Home = () => {
  const [filteredBooks, setFilteredBooks] = useState([])

  useEffect(() => {
    const getBooks = async () => {
      try {
        const booksData = await get(`jq/v2/books/type/7`)
        filterBooks(booksData)
      } catch (error) {
        console.error('Failed to fetch books', error)
      }
    }

    const filterBooks = (booksData) => {
      const bookIds = getItem('bookIds') || ''
      const bookIdArray = bookIds
        .split(',')
        .map((id) => id.trim())
        .filter((id) => id !== '')
      const filtered = booksData.filter((book) =>
        bookIdArray.includes(book.id.toString())
      )
      setFilteredBooks(filtered)
    }

    getBooks()
  }, [])

  return (
    <div className="container">
      <Link className="card" to="/pages/phonetic/index">
        <img
          className="card-image"
          src={jqImage('/static/jq/speak.png')}
          alt="音标训练"
        />
        <div className="card-title">音标训练</div>
      </Link>

      {filteredBooks &&
        filteredBooks.map((item, index) => (
          <Link
            key={index}
            className="card"
            to={`/pages/tiku/unit?bookId=${item.id}&bookName=${item.name}`}
          >
            <img
              className="card-image"
              src={formatOssUrl(`english${item.cover}`)}
              alt={item.name}
            />
            <div className="card-title">{item.name}</div>
          </Link>
        ))}
    </div>
  )
}

export default Home
