import axios from 'axios'
import { baseUrl } from './url'
import { openDialog } from '@/component/dialog/dialogController'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

import {
  getItemAsync,
  setItemAsync,
  setItem,
  clearStorageAsync,
} from '@/utils/storage'

// 创建一个 axios 实例
const apiClient = axios.create({
  // '/'代表使用proxy配置的端口
  baseURL: process.env.REACT_APP_DEBUG === 'true' ? '/' : baseUrl,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
})

let baseHttpParams = {
  uid: 0,
  sid: 0,
  dev_id: '',
}

// 请求拦截器
apiClient.interceptors.request.use(
  (config) => {
    config.params = { ...baseHttpParams, ...config.params }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
apiClient.interceptors.response.use(
  (response) => {
    // 将响应数据中的 liaozhishi.com 替换为 jingqiai.com
    const processedData = JSON.stringify(response.data).replace(
      /liaozhishi\.com/g,
      'jingqiai.com'
    )

    // 解析处理过的 JSON 数据
    const parsedData = JSON.parse(processedData)

    const { k_error, msg, data } = parsedData
    if (k_error === 0) {
      return data
    } else if ([2001, 2002, 2003].includes(k_error)) {
      let message = msg
      if (k_error === 2002) {
        message = '你的账号在其他地方被登录'
      } else if (k_error === 2003) {
        message = '会员过期'
      }
      openDialog(message)

      // 获取当前页面路由信息
      const currentPage = window.location.hash

      if (currentPage) {
        // 存储当前页面路由信息到 localStorage
        setItem('redirectFrom', currentPage)
      }
      window.location = '#/pages/login/index'

      return
    } else {
      openDialog(msg)
      return Promise.reject(response)
    }
  },
  (error) => {
    console.error(error)
    return Promise.reject(error)
  }
)

// 修改 baseHttpParams
export const addBaseHttpParams = (key, value) => {
  baseHttpParams[key] = value
}

// 封装 GET 请求
export const get = async (url, params) => {
  try {
    const response = await apiClient.get(url, { params })
    return response
  } catch (error) {
    throw error
  }
}

// 封装 POST 请求
export const post = async (url, data) => {
  try {
    const response = await apiClient.post(url, { subject_code: '3', ...data })
    return response
  } catch (error) {
    throw error
  }
}

const getCurrentUserAgent = () => {
  const userAgent = navigator.userAgent
  console.log(userAgent)
  if (userAgent.indexOf('MicroMessenger') > -1) {
    return 'Wechat'
  } else if (userAgent.indexOf('Chrome') > -1) {
    return 'Chrome'
  } else if (userAgent.indexOf('Firefox') > -1) {
    return 'Firefox'
  } else if (userAgent.indexOf('Safari') > -1) {
    return 'Safari'
  } else if (
    userAgent.indexOf('MSIE') > -1 ||
    userAgent.indexOf('Trident/') > -1
  ) {
    return 'IEOrEdge'
  } else {
    return 'Other'
  }
}

const getVisitorId = async () => {
  const fp = await FingerprintJS.load()
  const result = await fp.get()
  const visitorId = result.visitorId
  return visitorId ?? Math.floor(Math.random() * 90000000) + 10000000
}

export const initHttpParams = async () => {
  try {
    let devId = await getItemAsync('devId')
    if (!devId || !(devId + '').startsWith('userAgent')) {
      devId = 'userAgent_' + (await getVisitorId())
      await setItemAsync('devId', devId)
    }
    addBaseHttpParams('dev_id', devId)

    const uid = await getItemAsync('uid')
    if (uid) {
      addBaseHttpParams('uid', uid)
    }

    const sid = await getItemAsync('sid')
    if (sid) {
      addBaseHttpParams('sid', sid)
    }

    return uid && sid
  } catch (error) {
    console.error(error)
    await clearStorageAsync()
  }
}
