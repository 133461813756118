import './login.scss'
import React, { useState, useEffect } from 'react'
import { getItemAsync, setItemAsync, removeItemAsync } from '@/utils/storage'
import { addBaseHttpParams, post } from '@/api/http'
import { jqImage } from '@/api/url'
import { useDialog } from '@/component/dialog/dialogContext'

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [captcha, setCaptcha] = useState('')
  const [showQR, setShowQR] = useState(false)
  const { openDialog } = useDialog()

  useEffect(() => {
    const init = async () => {
      const savedPhone = await getItemAsync('loginPhone')
      const savedCaptcha = await getItemAsync('password')
      setPhoneNumber(savedPhone || '')
      setCaptcha(savedCaptcha || '')
    }
    init()
  }, [])

  const handlePhoneChange = async (e) => {
    const newPhoneNumber = e.target.value
    setPhoneNumber(newPhoneNumber)
    await setItemAsync('loginPhone', newPhoneNumber)
  }

  const handleCaptchaChange = (e) => {
    setCaptcha(e.target.value)
  }

  const showQRCode = () => {
    setShowQR(true)
  }

  const login = async () => {
    if (!/^\d{11}$/.test(phoneNumber)) {
      openDialog('请输入正确的账号')
      return
    }
    if ((captcha + '').length < 4) {
      openDialog('请输入正确的密码')
      return
    }

    try {
      const res = await post('jq/v2/user/validate', {
        phone: phoneNumber,
        password: captcha + '',
      })

      if (res.result === 0) {
        await setItemAsync('uid', res.uid)
        await setItemAsync('sid', res.sid)
        await setItemAsync('leftDays', res.left_days)
        addBaseHttpParams('uid', res.uid)
        addBaseHttpParams('sid', res.sid)

        const redirectFrom = await getItemAsync('redirectFrom')
        if (redirectFrom?.length > 0) {
          window.location = redirectFrom
          await removeItemAsync('redirectFrom')
        } else {
          window.location.href = '/'
        }
      } else {
        openDialog('帐号与密码不匹配')
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div className="login-container">
      <img src={jqImage('/static/logo_jq.png')} className="logo" alt="Logo" />
      <div className="form-title">登陆</div>
      <div className="form-content">
        <div className="input-group">
          <input
            className="input-field"
            type="number"
            placeholder="请输入帐号"
            value={phoneNumber}
            onChange={handlePhoneChange}
          />
        </div>
        <div className="input-group">
          <input
            className="input-field"
            placeholder="请输入密码"
            value={captcha}
            onChange={handleCaptchaChange}
          />
        </div>
        <button className="login-btn" type="button" onClick={login}>
          登陆
        </button>
      </div>

      <div className="tip" onClick={showQRCode}>
        还没有密码？请添加<span className="contract">答疑老师</span>
        免费自动获取密码
      </div>

      {showQR && (
        <div className="qr-code-container" onClick={() => setShowQR(false)}>
          <div className="content" onClick={(e) => e.stopPropagation()}>
            <img
              className="qr-code"
              src={jqImage('/jingqi/bookJq.png')}
              alt="QR Code"
            />
            <div className="tip">长按二维码添加答疑老师</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Login
