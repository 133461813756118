import React, { useState, useEffect } from 'react'
import { getItemAsync, setItemAsync } from '@/utils/storage'
import { post } from '@/api/http'
import { jqImage } from '@/api/url'
import { useDialog } from '@/component/dialog/dialogContext'
import { useSearchParams } from 'react-router-dom'
import RegisterSuccess from './ component/register_success'
import './register.scss'
import useCaptchaTimer from '@/hook/useCaptchaTimer'
import Loading from '@/component/loading/loading'

const RegisterAnti = () => {
  const [user, setUser] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [params] = useSearchParams()
  const antiCode = params.get('code')
  const { openDialog } = useDialog()

  const { captchaTime, resetCaptchaTime, number } = useCaptchaTimer()

  useEffect(() => {
    const init = async () => {
      setPhoneNumber(await getItemAsync('loginPhone'))
    }
    init()
  }, [])

  const onInputPhone = async (e) => {
    const value = e.target.value
    setPhoneNumber(value)
    await setItemAsync('loginPhone', value)
  }

  const getCaptcha = async () => {
    if (captchaTime < 120) {
      openDialog('请勿频繁发送验证码')
      return
    }
    if (!/^\d{11}$/.test(phoneNumber)) {
      openDialog('请输入正确的11位手机号')
      return
    }
    try {
      const res = await post('jq/v2/user/send_verify_code', {
        phone: phoneNumber,
      })
      if (res.result === 0) {
        resetCaptchaTime()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleRegister = async () => {
    if (!/^\d{11}$/.test(phoneNumber)) {
      openDialog('请输入正确的11位手机号')
      return
    } else if (verificationCode.toString().length < 4) {
      openDialog('请输入验证码')
      return
    } else if (password.toString().length < 4) {
      openDialog('请输入至少4位密码')
      return
    } else if (/\s/.test(password)) {
      openDialog('密码不能包含空字符')
      return
    }

    try {
      setIsLoading(true)
      const res = await post('jq/v2/user/verify', {
        phone: phoneNumber,
        verifyCode: verificationCode,
        password,
        antiCode,
      })
      const { result } = res
      if (result === 0) {
        setUser(res.user)
      } else if (result === 2) {
        openDialog('验证码输入错误')
      } else if (result === 3) {
        openDialog(`您的课本已绑定${res.phone}，请使用该手机号`)
      } else if (result === 4) {
        openDialog('防伪码错误')
      }
    } catch (error) {
      console.error('Error registering user:', error)
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return <Loading loadingText="加载..." />
  }

  return (
    <div>
      {user ? (
        <RegisterSuccess user={user} />
      ) : (
        <div className="register-container">
          <img
            src={jqImage('/static/logo_jq.png')}
            alt="Logo"
            className="logo"
          />
          <h3>账号注册</h3>
          <div className="form-group">
            <label htmlFor="phoneNumber">手机号:</label>
            <input
              id="phoneNumber"
              type="number"
              value={phoneNumber}
              onChange={onInputPhone}
              placeholder="请输入手机号"
            />
          </div>
          <div className="form-group verification-container">
            <input
              type="text"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              placeholder="请输入验证码"
            />
            <button
              type="button"
              className={`verification-button ${captchaTime < 120 ? 'disable' : ''}`}
              onClick={getCaptcha}
            >
              {captchaTime < 120
                ? `${captchaTime}s`
                : number === 0
                  ? '发送验证码'
                  : '重新获取验证码'}
            </button>
          </div>
          <div className="form-group">
            <label htmlFor="password">密码:</label>
            <input
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="请输入至少4位密码"
            />
          </div>
          <div className="form-group">
            <button onClick={handleRegister} className="register-button">
              注册
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default RegisterAnti
